<template>
  <div v-if="isReady">
    <asset-header-card
      :asset="asset"
      :fftlog="false"
      :three-dimensional="true"
      :telemetry="false"
    />
    <asset-log-three
      :asset-i-d=" asset.assetID"
    />
  </div>
</template>

<script>

import store from '@/store/index'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { useToast } from 'vue-toastification/composition'
import { onUnmounted, ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import assetFactoryStore from '../assetsLogStore'
import AssetHeaderCard from '../components/AssetHeaderCard.vue'
import AssetLogThree from './AssetLogThreeDimension.vue'

export default {
  components: {
    AssetLogThree,
    AssetHeaderCard,

  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-asset-log'
    const toast = useToast()
    const isReady = ref(false)
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, assetFactoryStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const asset = ref({})
    store.dispatch('app-asset-log/fetchThisAssets', router.currentRoute.params.assetID)
      .then(response => {
        const { data } = response
        // eslint-disable-next-line prefer-destructuring
        asset.value = data[0]
        isReady.value = true
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Asset Info',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    return {
      asset,
      isReady,
    }
  },
}
</script>
