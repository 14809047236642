<template>
  <div>
    <div style="height: 70vh;width: 100%;">
      <e-charts
        :update-options="{notMerge:true}"
        autoresize
        :option="option"
        theme="theme-color"
        style="height: 68vh;width: 100%;"
        auto-resize
      />
    </div>
  </div>
</template>

<script>
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { GaugeChart, BarChart, ScatterChart } from 'echarts/charts'
import { Scatter3DChart, Bar3DChart } from 'echarts-gl/charts'
import { Grid3DComponent } from 'echarts-gl/components'
import {
  TitleComponent, GridComponent, DataZoomComponent, DatasetComponent, TooltipComponent, LegendComponent, VisualMapComponent,
} from 'echarts/components'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

use([
  CanvasRenderer,
  GaugeChart,
  BarChart,
  ScatterChart,
  Scatter3DChart,
  Bar3DChart,
  Grid3DComponent,
  TitleComponent,
  GridComponent,
  DataZoomComponent,
  DatasetComponent,
  TooltipComponent,
  LegendComponent,
  VisualMapComponent,
])

export default {
  components: {
    ECharts,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    assetID: {
      required: true,
    },
  },
  data() {
    return {
      timer: null,
      option: {},
    }
  },
  created() {
    this.getItems()
  },
  methods: {
    getItems() {
      store
        .dispatch('app-asset-log/fetch3DFFTData', { assetID: this.assetID })
        .then(response => {
          this.plotFFT(response.data)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching devices list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    plotFFT(data) {
      const highest = data.reduce((previous, current) => (current[2] > previous[2] ? current : previous))
      this.option = {
        grid3D: {},
        tooltip: {},
        xAxis3D: {
          type: 'category',
        },
        yAxis3D: {
          type: 'category',
        },
        zAxis3D: {},
        visualMap: {
          max: highest[2],
          dimension: 'magnitude',
        },
        dataset: {
          dimensions: [
            'tripID',
            'frequency',
            'magnitude',
          ],
          source: data,
        },
        series: [
          {
            type: 'bar3D',
            // symbolSize: symbolSize,
            shading: 'lambert',
          },
        ],
      }
    },
  },
}
</script>
